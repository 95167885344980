'use client';

import { usePathname } from 'next/navigation';
import { Suspense } from 'react';

import SheetStack from '@/components/display/SheetStack';
import { cn } from '@/lib/utils/twutils';

export default function BaseLayout(props: React.PropsWithChildren) {
  const pathname = usePathname();
  const isLanding = pathname?.includes('landing');

  return (
    <div className={cn('h-[calc(100vh-42px)] overflow-hidden relative', { 'h-screen': isLanding })}>
      <div className="h-full overflow-scroll">
        <Suspense>{props.children}</Suspense>
        <SheetStack />
      </div>
    </div>
  );
}
